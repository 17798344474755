<template>
  <div v-if="platformPermissionsLoaded && checkPermission('measure.sq.sqinsights')">
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Pillars_insights')}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="link m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow class="insights_filters">
      <CCol>
        <b-datepicker v-model="dateFrom"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getCategoriesData()"
                      class="d-inline-block align-middle mr-2">
          <CButton color="primary" @click="dateFrom = new Date(); getCategoriesData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol>
        <b-datepicker v-model="dateTo"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getCategoriesData()"
                      class="d-inline-block align-middle">
          <CButton color="primary" @click="dateTo = new Date(); getCategoriesData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol>
        <multiselect class="data_table"
                      v-model="selectedDepartments" 
                      :options="departments" 
                      :multiple="true"                
                      :placeholder="$t('common.Select_department')"
                      :selectLabel="$t('common.Add_department')" 
                      :selectedLabel="$t('Added')"
                      :deselectLabel="$t('common.Remove_department')"
                      track-by="department_id" 
                      label="department_name"
                      @search-change="asyncFindDepartment"
                      @input="getCategoriesData()">
          <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
        </multiselect>
      </CCol>
      <CCol>
        <multiselect class="data_table"
                      v-model="selectedTeams" 
                      :options="teams" 
                      :multiple="true"                
                      :placeholder="$t('common.Select_team')" 
                      :selectLabel="$t('common.Add_team')" 
                      :selectedLabel="$t('Added')"
                      :deselectLabel="$t('common.Remove_team')"
                      track-by="team_id" 
                      label="team_name"
                      @search-change="asyncFindTeam"
                      @input="getCategoriesData()">
          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
        </multiselect>
      </CCol>
      <CCol>
        <multiselect class="data_table"
                      v-model="selectedWorkLocations" 
                      :options="workLocations" 
                      :multiple="true"                
                      :placeholder="$t('common.Select_work_location')" 
                      :selectLabel="$t('common.Add_work_location')" 
                      :selectedLabel="$t('Added')"
                      :deselectLabel="$t('common.Remove_work_location')"
                      track-by="tag" 
                      label="label"
                      @search-change="asyncFindWorkLocation"
                      @input="getCategoriesData()">
          <span slot="noResult">{{ $t('common.No_work_locations_found') }}</span>
          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
        </multiselect>
      </CCol>      
    </CRow>

    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">
      <CCol cols="4" xl="4" lg="4" md="4" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('common.Sentiment')}}
              </CCol>       
            </CRow>
          </CCardHeader>
          <CCardBody class="text-center">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
              <div v-if="categoriesInsightsData.sentiment_score">
                <Grade :grade="categoriesInsightsData.sentiment_score.average_score" :centered="false"/>
                <ReachDigits :nDigit="categoriesInsightsData.sentiment_score.n_digit" :qDigit="categoriesInsightsData.sentiment_score.q_digit" class="mt-1"/>
              </div>
              <div v-else class="text-center">
                <span>{{ $t('measure.not_enough_sentiment_score_data') }}</span>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('common.eNPS')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <NetPromoterScore v-else :enps="categoriesInsightsData.enps"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">      
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('common.Emoji_cloud')}}
              </CCol>
            </CRow>      
          </CCardHeader>
          <CCardBody>
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <EmojiCloud v-else :emojiCloud="categoriesInsightsData.emoji_cloud"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="6" xl="6" lg="6" md="6" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                {{$t('insights.Trend')}}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <SentimentChart v-else :sentimentChartSeries="sentimentChartSeries" xAxisType="datetime" yAxisType="decimal" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="6" xl="6" lg="6" md="6" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                {{$t('common.Highlights')}}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !loadingData}">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <HighlightStage v-else
                            :stageData="categoriesInsightsData.sentiment"
                            :scoreValue="'highlight_score'"
                            :nameValue="'score_item'"
                            :idValue="'score_item_id_external'"
                            :showDecimals="true"
                            :navigateTo="'measure/category'"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader>
            <CRow>
              <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
                {{$t('insights.Performance_pillars')}}
              </CCol>
              <CCol v-if="showDownloadButtons" cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
                <div class="mt-1 mt-xl-0 d-inline-block align-top mr-2">
                  <CButton class="link m-0" color="primary" @click="getSentimentExportData('csv')">
                    <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_csv') }}</span>
                  </CButton>                
                </div>
                <div class="mt-1 mt-xl-0 d-inline-block align-top">
                  <CButton class="link m-0" color="primary" @click="getSentimentExportData('json');">
                    <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_json') }}</span>              
                  </CButton>
                </div>              
              </CCol>  
            </CRow>
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !loadingData}">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <SentimentTable v-else :platformPermissions="platformPermissions" :sentiment="categoriesInsightsData.sentiment" :navigateTo="'category'"/>
          </CCardBody>
        </CCard>
      </CCol>       
    </CRow>   
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Multiselect from 'vue-multiselect';
import noPermission from '@/components/common/noPermission.vue';

import Grade from '@/components/insights/common/Grade.vue';
import ReachDigits from '@/components/insights/common/ReachDigits.vue';
import HighlightStage from '@/components/insights/common/HighlightStage.vue';

import SentimentChart from '@/components/insights/measure/SentimentChart.vue';
import SentimentTable from '@/components/insights/measure/SentimentTable.vue';
import NetPromoterScore from '@/components/insights/measure/NetPromoterScore.vue';
import EmojiCloud from '@/components/insights/measure/EmojiCloud.vue';

export default {
  name: 'Categories',
  components: {
    loadingSpinner,
    Multiselect,
    noPermission,
    Grade,
    ReachDigits,
    SentimentChart,
    NetPromoterScore,
    EmojiCloud,
    SentimentTable,
    HighlightStage
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      loadingData: false,
      categoriesInsightsData: {
        sentiment_score: {
          average_score: '0.0',
          n_digit: 0,
          q_digit: 0
        },
        emoji_cloud: { data_available: false },
        enps: { data_available: false }
      },
      showDownloadButtons: false,
      printingInsights: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      companyPrimaryColor: null,
      sentimentChartSeries: [],
      selectedTeams: [],
      teams: [],
      selectedDepartments: [],
      departments: [],
      selectedWorkLocations: [],
      workLocations: []           
    }
  },
  methods: {
    getCategoriesData() {
      let params = {};
      params.departments = [];
      params.teams = [];
      params.work_locations = [];

      for(let d = 0; d < this.selectedDepartments.length; d++) {
        params.departments.push(this.selectedDepartments[d].department_id);
      }

      for(let t = 0; t < this.selectedTeams.length; t++) {
        params.teams.push(this.selectedTeams[t].team_id);
      }

      for(let l = 0; l < this.selectedWorkLocations.length; l++) {
        params.work_locations.push(this.selectedWorkLocations[l].tag);
      }      

      // Start the loader
      this.loadingData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");    
      // Get the topics insights data
      axios.post(process.env.VUE_APP_API_URL + '/v1/measure/categories/insights/' + dateFromAPI + '/' + dateToAPI, params)
      .then(res => {
        // Set the categoriesInsightsData
        this.categoriesInsightsData = res.data.data;
        // Update the sentimentChartSeries
        this.sentimentChartSeries = [{ name: "Sentiment Score", data: this.categoriesInsightsData.sentiment_score_trend }];
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingData = false;              
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
      link.setAttribute("download", "pillars_insights_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    getSentimentExportData(outputFormat) {
      let params = {};
      params.departments = [];
      params.teams = [];
      params.work_locations = [];

      for(let d = 0; d < this.selectedDepartments.length; d++) {
        params.departments.push(this.selectedDepartments[d].department_id);
      }

      for(let t = 0; t < this.selectedTeams.length; t++) {
        params.teams.push(this.selectedTeams[t].team_id);
      }

      for(let l = 0; l < this.selectedWorkLocations.length; l++) {
        params.work_locations.push(this.selectedWorkLocations[l].tag);
      }       
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
      // Export the data
      axios.post(process.env.VUE_APP_API_URL + '/v1/measure/categories/insights/performance/' +  dateFromAPI + '/' + dateToAPI + '/export/' + outputFormat, params)
      .then(res => {
        let outputData = res;      
        this.exportData(outputData, outputFormat);
      })
      .catch(err => {
        console.error(err); 
      });
    },
    exportData(outputData, outputFormat) {
      this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });

      let exportFileName = "pillars-insights-performance";

      if (!window.navigator.msSaveOrOpenBlob){
        // Blob navigator
        let url = null;
        // Set the output data and URL based on the output format
        if(outputFormat === 'csv') {
          outputData = outputData.data;
          url = window.URL.createObjectURL(new Blob([outputData]));  
        } else if(outputFormat === 'json') {
          outputData = JSON.stringify(outputData.data.data);
          url = window.URL.createObjectURL(new Blob([outputData]));
        }
        // Create a new 'a' element
        const link = document.createElement('a');
        // Set the generated URL as href of the new element
        link.href = url;
        // Set the download attribute based on the output format
        if(outputFormat === 'csv') {          
          link.setAttribute('download', exportFileName + '.csv');
        } else if(outputFormat === 'json') {
          link.setAttribute('download', exportFileName + '.json');
        }
        // Append the link to the body
        document.body.appendChild(link);
        // Click the link
        link.click();
      } else {
        // Blob for Explorer 11
        let url = null;
        // Set the output data and URL based on the output format
        if(outputFormat === 'csv') {
          outputData = outputData.data;
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.csv');
        } else if(outputFormat === 'json') {
          outputData = JSON.stringify(outputData.data.data);
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.json');
        }
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    asyncFindDepartment (query) {
      let searchTerm = query;
      this.departments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.departments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam (query) {
      let searchTerm = query;
      this.teams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.teams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindWorkLocation(query) {
      let searchTerm = query;
      this.workLocations = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/workLocation/' + searchTerm)
        .then(res => {      
          this.workLocations = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    }
  },
  mounted: function() {
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);
    // Get the data
    this.getCategoriesData();
    this.getPlatformPermissions();
  }
}
</script>