<template>
  <CRow>
    <CCol cols="12" lg="12" class="pt-0 pb-md-0">
      <b-table class="data_table"
               :data="sentimentData"
               :striped="true"
               :hoverable="true"
               :mobile-cards="true"
               :paginated="isPaginated"
               :per-page="perPage"
               :current-page.sync="currentPage"
               :pagination-simple="isPaginationSimple"
               :pagination-position="paginationPosition"
               :default-sort-direction="'desc'"
               :sort-icon="sortIcon"
               :sort-icon-size="sortIconSize"
               default-sort="score.average_score">
        
        <template slot-scope="props">
          <b-table-column field="score_item" :label="$t('sq.Pillar')">            
            <span @click="(checkPermission('measure.sq.pillarinsights') || checkPermission('measure.sq.subpillarinsights')) ? navigateToDetails(props.row.score_item_id_external) : null" v-bind:class="{'d-flex align-items-center pointer' : navigateTo}">
              {{props.row.score_item}}<i v-if="navigateTo && (checkPermission('measure.sq.pillarinsights') || checkPermission('measure.sq.subpillarinsights'))" class="fas fa-chevron-right ml-1"/>
            </span>
          </b-table-column>
          <b-table-column field="last_emojis" v-bind:label="$t('sq.Popular_reply')" :centered="true" width="10%">
            <span v-if="props.row.score && props.row.most_given_answer"
                  v-html="getTwemoji(props.row.most_given_answer.emoji)"
                  v-c-tooltip="{placement: 'right', boundaries: 'scrollParent', content: props.row.most_given_answer.times_given + ' ' + $t('sq.times_given')}">
            </span>
            <span v-else>-</span>
          </b-table-column>          
          <b-table-column field="score.average_score" class="score" v-bind:label="$t('sq.Score')" :centered="true" sortable width="12.5%">
            <div v-if="props.row.score" class="item_score text-center">
              <span class="integral">{{ getScoreIntegral(props.row.score.average_score) }}</span>
              <span class="decimal">.</span>
              <span class="fractional">{{ getScoreFracetional(props.row.score.average_score) }}</span>
              <span class="d-block mt-1" style="font-size: 0.7em;">N = {{props.row.score.n_digit}} / Q = {{props.row.score.q_digit}}</span>
            </div>
            <span v-else>-</span>
          </b-table-column>                
          <b-table-column field="score_month.average_score"
                          class="score"
                          v-bind:class="{increased : props.row.score_month && props.row.score_month.growth > 0, decreased : props.row.score_month && props.row.score_month.growth < 0}"
                          v-bind:label="30 + ' ' + $t('common.days')"
                          :centered="true"
                          sortable
                          width="12.5%">

            <div v-if="props.row.score_month" class="item_score text-center">
              <span class="integral">{{ getScoreIntegral(props.row.score_month.average_score) }}</span>
              <span class="decimal">.</span>
              <span class="fractional">{{ getScoreFracetional(props.row.score_month.average_score) }}</span>
              <div v-if="props.row.score_month.growth" class="d-inline ml-1">
                <i class="icon fas" v-bind:class="{'fa-arrow-circle-up': props.row.score_month.growth > 0, 'fa-arrow-circle-down': props.row.score_month.growth < 0}"/>
                <span class="growth">{{props.row.score_month.growth}}</span>        
              </div>
              <span class="d-block mt-1" style="font-size: 0.7em;">N = {{props.row.score_month.n_digit}} / Q = {{props.row.score_month.q_digit}}</span>
            </div>
            <span v-else>-</span>                                    
          </b-table-column>
          <b-table-column field="score_six_month.average_score"
                          class="score"
                          v-bind:class="{increased : props.row.score_six_month && props.row.score_six_month.growth > 0, decreased : props.row.score_six_month && props.row.score_six_month.growth < 0}"
                          v-bind:label="6 + ' ' + $t('common.months')"
                          :centered="true"
                          sortable
                          width="12.5%">
            
            <div v-if="props.row.score_six_month" class="item_score text-center">
              <span class="integral">{{ getScoreIntegral(props.row.score_six_month.average_score) }}</span>
              <span class="decimal">.</span>
              <span class="fractional">{{ getScoreFracetional(props.row.score_six_month.average_score) }}</span>
              <div v-if="props.row.score_six_month.growth" class="d-inline ml-1">
                <i class="icon fas" v-bind:class="{'fa-arrow-circle-up': props.row.score_six_month.growth > 0, 'fa-arrow-circle-down': props.row.score_six_month.growth < 0}"/>
                <span class="growth">{{props.row.score_six_month.growth}}</span>        
              </div>
              <span class="d-block mt-1" style="font-size: 0.7em;">N = {{props.row.score_six_month.n_digit}} / Q = {{props.row.score_six_month.q_digit}}</span>
            </div>
            <span v-else>-</span>               
          </b-table-column>
          <b-table-column field="score_twelve_month.average_score"
                          class="score"
                          v-bind:class="{increased : props.row.score_twelve_month && props.row.score_twelve_month.growth > 0, decreased : props.row.score_twelve_month && props.row.score_twelve_month.growth < 0}"
                          v-bind:label="12 + ' ' + $t('common.months')"
                          :centered="true"
                          sortable
                          width="12.5%">

            <div v-if="props.row.score_twelve_month" class="item_score text-center">
              <span class="integral">{{ getScoreIntegral(props.row.score_twelve_month.average_score) }}</span>
              <span class="decimal">.</span>
              <span class="fractional">{{ getScoreFracetional(props.row.score_twelve_month.average_score) }}</span>
              <div v-if="props.row.score_twelve_month.growth" class="d-inline ml-1">
                <i class="icon fas" v-bind:class="{'fa-arrow-circle-up': props.row.score_twelve_month.growth > 0, 'fa-arrow-circle-down': props.row.score_twelve_month.growth < 0}"/>
                <span class="growth">{{props.row.score_twelve_month.growth}}</span>        
              </div>               
              <span class="d-block mt-1" style="font-size: 0.7em;">N = {{props.row.score_twelve_month.n_digit}} / Q = {{props.row.score_twelve_month.q_digit}}</span>
            </div>
            <span v-else>-</span>
          </b-table-column>                                             
        </template>
        <template slot="empty">
          <div class="text-center">
            <span class="text-center">{{ $t('sq.not_enough_performance_data') }}</span>                                           
          </div>                       
        </template>          
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'SentimentTable',
  props: ['platformPermissions', 'sentiment', 'navigateTo'],
  watch: {
    $props: {
      handler() {
        if(this.sentiment) {
          // Set the prop as the table data          
          this.sentimentData = Object.values(this.sentiment);
          // Check if the pagination should be activated
          (this.sentimentData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;               
          // Calculate the growth
          this.getGrowth();
        }
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      sentimentData: [],
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',      
      searchEnabled: false        
    }
  },
  methods: {
    getGrowth() {      
      for(var d = 0; d < this.sentimentData.length; d++) {
        if(this.sentimentData[d].score && this.sentimentData[d].score_month) {
          const growtMonth = (parseFloat(this.sentimentData[d].score_month.average_score) - parseFloat(this.sentimentData[d].score.average_score)).toFixed(1);          
          this.sentimentData[d].score_month.growth = parseFloat(growtMonth);
        }

        if(this.sentimentData[d].score && this.sentimentData[d].score_six_month) {
          const growthSixMonth = (parseFloat(this.sentimentData[d].score_six_month.average_score) - parseFloat(this.sentimentData[d].score.average_score)).toFixed(1);
          
          this.sentimentData[d].score_six_month.growth = parseFloat(growthSixMonth);
        }
        
        if(this.sentimentData[d].score && this.sentimentData[d].score_twelve_month) {
          const growthTwelveMonth = (parseFloat(this.sentimentData[d].score_twelve_month.average_score) - parseFloat(this.sentimentData[d].score.average_score)).toFixed(1);          
          this.sentimentData[d].score_twelve_month.growth = parseFloat(growthTwelveMonth);
        }         
      }
    },
    getScoreIntegral(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(0, score.indexOf('.'));
      } else {
        score = score.toFixed(1).toString();
        return score.substring(0, score.indexOf('.'));
      }
    },
    getScoreFracetional(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(score.indexOf('.') + 1);
      } else {
        score = score.toFixed(1).toString();
        return score.substring(score.indexOf('.') + 1);
      }
    },
    navigateToDetails(id) {
      if(this.navigateTo) this.$router.push({path: `/insights/measure/${this.navigateTo}/${id.toString()}`});
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  }
}
</script>